import { useState, useEffect } from "react";
import MetaComponent from "../components/Meta";
import { BRAND_TAGLINE } from "../lib";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import app from "../firebase.config";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { Field, Form, Formik } from "formik";
import { InitialValuesType } from "../lib/types";
import CustomSelect from "../components/CustomSelect";
import { useSession } from "next-auth/react";
import toast from "react-hot-toast";
import * as yup from "yup";

const db = getFirestore(app);

const Home = () => {
  const { data: session } = useSession();

  const [items, setItems] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const initialValues: InitialValuesType = {
    startDate: "",
    endDate: "",
    name: "",
    attendees: [""],
    description: "",
  };

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    const users: any = [];
    querySnapshot.forEach((doc) => {
      users.push(doc.data());
    });
    setItems(
      users?.map((result: any) => ({ value: result.email, label: `${result.name} (${result.sub_type})` }))
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const ValidationSchema = yup.object().shape({
    startDate: yup
      .date()
      .required("Start date is required")
      .min(new Date(), "Start date cannot be in the past"),
    endDate: yup
      .date()
      .required("End date is required")
      .min(yup.ref("startDate"), "End date must be after start date"),
    name: yup
      .string()
      .required("Name is required")
      .min(2, "Name must be at least 3 characters")
      .max(50, "Name must be less than 50 characters"),
    attendees: yup
      .array()
      .required("Attendees is required")
      .min(1, "At least one attendee is required")
      .max(500, "Maximum of 500 attendees is allowed"),
    description: yup
      .string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters long")
      .max(500, "Description must be less than 500 characters long"),
  });

  const createEvent = async (values: InitialValuesType) => {
    const { startDate, endDate, name, attendees, description } = values;

    const token = session?.accessToken;

    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion=1",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            summary: name,
            description: description,
            start: {
              dateTime: `${startDate}:00-00:00`,
            },
            end: {
              dateTime: `${endDate}:00-00:00`,
            },
            attendees: attendees,
            reminders: {
              useDefault: true,
            },
            sendUpdates: "all",
            conferenceData: {
              createRequest: {
                conferenceSolutionKey: {
                  type: "hangoutsMeet",
                },
                requestId: "somerequestid",
              },
            },
          }),
        }
      );
      const data = await response.json();

      if (data.error) {
        toast.error(data.error.message);
      } else {
        toast.success("Event created successfully");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MetaComponent title={BRAND_TAGLINE} />
      <Layout>
        {loading && <Loader text={"creating event..."} />}
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Create Event
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Use the form below to create a Google Meets event
              </p>
            </div>
          </div>

          <Formik
            validationSchema={ValidationSchema}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              await new Promise((resolve) => setTimeout(resolve, 500));
              setLoading(true);
              try {
                createEvent(values);
              } catch (error) {
                toast.error("Error creating event");
                console.log(error);
              } finally {
                resetForm();
              }
            }}
          >
            {({ errors, touched }) => (
              <Form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                <div className="mt-4">
                  <label
                    htmlFor="startDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Start Date
                  </label>
                  <div className="mt-1">
                    <Field
                      type="dateTime-local"
                      id="startDate"
                      name="startDate"
                      autoComplete="startDate"
                      className={`block w-full border-gray-300 rounded-md shadow-sm ${
                        errors.startDate && touched.startDate
                          ? "focus:ring-red-500 focus:border-red-500"
                          : "focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      } `}
                    />
                    {errors && touched.startDate && (
                      <span className="text-red-500 hover:text-red-700">
                        {errors.startDate}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="endDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    End Date
                  </label>
                  <div className="mt-1">
                    <Field
                      type="dateTime-local"
                      id="endDate"
                      name="endDate"
                      autoComplete="endDate"
                      className={`block w-full border-gray-300 rounded-md shadow-sm ${
                        errors.endDate && touched.endDate
                          ? "focus:ring-red-500 focus:border-red-500"
                          : "focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      } `}
                    />
                    {errors && touched.endDate && (
                      <span className="text-red-500 hover:text-red-700">
                        {errors.endDate}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Name
                  </label>
                  <div className="mt-1">
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      autoComplete="name"
                      className={`block w-full border-gray-300 rounded-md shadow-sm ${
                        errors.name && touched.name
                          ? "focus:ring-red-500 focus:border-red-500"
                          : "focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      } `}
                    />
                    {errors && touched.name && (
                      <span className="text-red-500 hover:text-red-700">
                        {errors.name}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="attendees"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Attendees
                  </label>
                  <div className="mt-1">
                    <Field
                      type="text"
                      id="attendees"
                      name="attendees"
                      autoComplete="attendees"
                      options={items}
                      component={CustomSelect}
                      isMulti={true}
                      className={`block w-full border-gray-300 rounded-md shadow-sm ${
                        errors.attendees && touched.attendees
                          ? "focus:ring-red-500 focus:border-red-500"
                          : "focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      } `}
                    />
                    {errors && touched.attendees && (
                      <span className="text-red-500 hover:text-red-700">
                        {errors.attendees}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Description
                    <small className="text-gray-500"></small>
                  </label>
                  <div className="mt-1">
                    <Field
                      name="description"
                      rows={4}
                      id="description"
                      as={"textarea"}
                      className={`block w-full border-gray-300 rounded-md shadow-sm ${
                        errors.description && touched.description
                          ? "focus:ring-red-500 focus:border-red-500"
                          : "focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      } `}
                    />
                    {errors && touched.description && (
                      <span className="text-red-500 hover:text-red-700">
                        {errors.description}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-4  py-6 px-4 sm:px-6">
                  <button
                    type="submit"
                    className="w-full bg-blue-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
                  >
                    Create Event
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Layout>
    </>
  );
};

export default Home;

Home.auth = true;
